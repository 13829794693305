// @flow
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  LOGIN_USER,
  LOGOUT_USER,
  FETCH_AUTH_USER
} from "../../constants/actionTypes";
import {
  loginUserSuccess,
  loginUserFailed,
  fetchAuthUserSuccess,
  fetchAuthUserFailed,
  logoutUser
} from "./actions";
import {
  setUserTokenOnCookie,
  removeUserTokenFromCookie,
  setCompany,
  removeCompany
} from "../../helpers/authUtils";
import routes from "../../constants/routes";
import { noAuthRequest, authRequest } from "../request";

function* login({ payload }) {
  try {
    const { username, password } = payload;
    const body = {
      document: username,
      password
    };
    const responseToken = yield call(noAuthRequest, "post", `/sessions`, body);
    setUserTokenOnCookie(responseToken.data.data);
    const reponseUser = yield call(authRequest, "get", `/sessions`);
    const user = reponseUser.data.data;
    if (user) {
      setCompany(user);
    }
    yield put(loginUserSuccess(user));
  } catch (error) {
    yield put(loginUserFailed());
  }
}

function* logout({ payload: { history } }) {
  removeUserTokenFromCookie();
  removeCompany();
  yield put(logoutUser());
  yield call(() => history.push(routes.login));
}

function* fetchUser() {
  console.log("xxx");

  try {
    const responseUser = yield call(authRequest, "get", `/sessions`);
    yield put(fetchAuthUserSuccess(responseUser.data.data));
  } catch (error) {
    yield put(fetchAuthUserFailed(error));
  }
}

export function* watchFetchUser() {
  yield takeEvery(FETCH_AUTH_USER, fetchUser);
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

function* authSaga() {
  yield all([
    fork(watchFetchUser),
    fork(watchLoginUser),
    fork(watchLogoutUser)
  ]);
}

export default authSaga;
