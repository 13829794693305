// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    createCompanyAcquirerBrandSuccess,
    createCompanyAcquirerBrandFailed,
    deleteCompanyAcquirerBrandFailed,
    deleteCompanyAcquirerBrandSuccess,
} from './actions';
import {
    listBrandsSuccess,
} from '../brands/actions';
import {
    CREATE_COMPANY_ACQUIRER_BRAND,
    DELETE_COMPANY_ACQUIRER_BRAND,
} from '../../constants/actionTypes';
import { getUserTokenFromCookie, getCompany } from '../../helpers/authUtils';
import { request } from '../request';

function* createAssociationWithBrandCompanyAcquirer({ payload }) {
    try {
        const token = getUserTokenFromCookie();
        const company = getCompany();
        const body = {
            acquirerSlug: payload.acquirerSlug
        };
        const response = yield call(request, `/companies/${company.id}/brands/${payload.brandSlug}/acquirers`, 'post', token, body);
        const brandsResponse = yield call(request, `/companies/${company.id}/brands-acquirers`, 'get', token);
        const selectedBrandsResponse = yield call(request, `/companies/${company.id}/active-brands-acquirers`, 'get', token);
        
        yield put(listBrandsSuccess({
            brands: brandsResponse.data.data,
            selectedBrands: selectedBrandsResponse.data.data,
        }));
        yield put(createCompanyAcquirerBrandSuccess(response.data.data));
    } catch (error) {
        yield put(createCompanyAcquirerBrandFailed(error));
    }
}

function* deleteAssociationWithBrandCompanyAcquirer({ payload }) {
    try {
        const token = getUserTokenFromCookie();
        const company = getCompany();
        const body = {
            acquirerSlug: payload.acquirerSlug
        };
        const response = yield call(request, `/companies/${company.id}/brands/${payload.brandSlug}/acquirers`, 'delete', token, body);
        const brandsResponse = yield call(request, `/companies/${company.id}/brands-acquirers/`, 'get', token);
        const selectedBrandsResponse = yield call(request, `/companies/${company.id}/active-brands-acquirers`, 'get', token);
        yield put(listBrandsSuccess({
            brands: brandsResponse.data.data,
            selectedBrands: selectedBrandsResponse.data.data,
        }));
        yield put(deleteCompanyAcquirerBrandSuccess(response.data.data));
        if (payload.callback) {
            payload.callback();
        }
    } catch (error) {
        yield put(deleteCompanyAcquirerBrandFailed(error));
    }
}

export function* watchAssociationWithBrandCompanyAcquirerCreation() {
    yield takeEvery(CREATE_COMPANY_ACQUIRER_BRAND, createAssociationWithBrandCompanyAcquirer);
}

export function* watchAssociationWithBrandCompanyAcquirerDeletion() {
    yield takeEvery(DELETE_COMPANY_ACQUIRER_BRAND, deleteAssociationWithBrandCompanyAcquirer);
}

function* authSaga() {
    yield all([
        fork(watchAssociationWithBrandCompanyAcquirerCreation),
        fork(watchAssociationWithBrandCompanyAcquirerDeletion),
    ]);
}

export default authSaga;