import {
    FETCH_COMPANY,
    FETCH_COMPANY_SUCCESS,
    FETCH_COMPANY_FAILED,
    EDIT_COMPANY,
    EDIT_COMPANY_SUCCESS,
    EDIT_COMPANY_FAILED,
} from '../../constants/actionTypes';

export const fetchCompany = (id) => {
    return {
        type: FETCH_COMPANY,
        payload: id,
    }
};

export const fetchCompanySuccess = (company) => {
    return {
        type: FETCH_COMPANY_SUCCESS,
        payload: company,
    }
};

export const fetchCompanyFailed = (error) => {
    return {
        type: FETCH_COMPANY_FAILED,
        payload: error,
    }
};

export const editCompany = (company, companyId) => {
    return {
        type: EDIT_COMPANY,
        payload: {
            company,
            companyId,
        },
    };
}

export const editCompanySuccess = (company) => {
    return {
        type: EDIT_COMPANY_SUCCESS,
        payload: company,
    };
}

export const editCompanyFailed = (error) => {
    return {
        type: EDIT_COMPANY_FAILED,
        payload: error,
    };
}
