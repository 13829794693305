// @flow
import {
    LIST_COMPANIES,
    LIST_COMPANIES_SUCCESS,
    CLEAN_COMPANIES,
    CREATE_COMPANY,
    CREATE_COMPANY_SUCCESS,
    CREATE_COMPANY_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    list: [],
    success: false,
    loading: false,
    error: false,
};

const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_COMPANY:
            return { ...state, loading: true, error: null };
        case CREATE_COMPANY_SUCCESS:
            return { ...state, loading: false, success: true, error: null };    
        case CREATE_COMPANY_FAILED:
            return { ...state, loading: false, error: true };
        case LIST_COMPANIES:
            return { ...state, loading: true };
        case LIST_COMPANIES_SUCCESS:
            return { ...state, list: [...state.list, ...action.payload], loading: false };
        case CLEAN_COMPANIES:
            return { ...INIT_STATE };
        default:
            return state;
    }
}

export default Auth;