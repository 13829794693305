import {
    LIST_USERS,
    LIST_USERS_SUCCESS,
    LIST_USERS_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    list: [],
    success: false,
    loading: false,
    error: false,
}

const Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_USERS:
            return { ...state, loading: true, error: null };
        case LIST_USERS_SUCCESS:
            return { ...state, list: action.payload, loading: false, success: true, error: null };
        case LIST_USERS_FAILED:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}

export default Reducer;