export default {
	dashboard: '/dashboard',
	companyList: '/stores/list',
	companyAdd: '/stores/add',
	gatewayAcquirers: '/gateway/settings/acquirers',
	gatewayBrands: '/gateway/settings/brands',
	salesList: '/sales/list',
	salesAdd: '/sales/add',
	salesQrCode: '/sales/qrcode',
	login: '/login',
	logout: '/logout',
	forgetPassword: 'forget-password',
	register: '/register',
	confirm: '/confirm',
	costumerList: '/costumer/list',
	companyEdit: param => `/companies/edit/${param}`,
	comapanyDetails: param => `/companies/details/${param}`,
	transactionsList: '/transactions/list'
};
