// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    listUsersSuccess,
    listUsersFailed,
} from './actions';
import {
    LIST_USERS,
} from '../../constants/actionTypes';
import { getUserTokenFromCookie } from '../../helpers/authUtils';
import { request } from '../request';

function* listUsers({ payload }) {
    try {
        const token = getUserTokenFromCookie();
        const response = yield call(request, `/list-users`, 'get', token);
        yield put(listUsersSuccess(response.data));
    } catch (error) {
        yield put(listUsersFailed(error));
    }
}

export function* watchListUsers() {
    yield takeEvery(LIST_USERS, listUsers);
}

function* authSaga() {
    yield all([
        fork(watchListUsers),
    ]);
}

export default authSaga;