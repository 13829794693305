// @flow
import {
    LIST_USERS,
    LIST_USERS_SUCCESS,
    LIST_USERS_FAILED,
} from '../../constants/actionTypes';

export const listUsers = (values) => {
    return {
        type: LIST_USERS,
        payload: values,
    }
};

export const listUsersSuccess = (result) => {
    return {
        type: LIST_USERS_SUCCESS,
        payload: result,
    }
};

export const listUsersFailed = (error) => {
    return {
        type: LIST_USERS_FAILED,
        payload: error,
    }
};