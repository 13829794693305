import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import Routes from './constants/routes';
import PrivateRoute from './PrivateRoute';

// lazy load all the views
const DashboardCompanyCreator = React.lazy(() => import('./pages/company/CompanyCreatorPage'));
const DashboardCompaniesList = React.lazy(() => import('./pages/company/CompaniesListPage'));
const DashboardCompanyDetails = React.lazy(() => import('./pages/company/CompanyDetailsPage'));
const DashboardGatewayAcquirers = React.lazy(() => import('./pages/gateway/GatewayAcquirersPage'));
const DashboardGatewayBrands = React.lazy(() => import('./pages/gateway/GatewayBrandsPage'));
const DashboardTransactionsList = React.lazy(() =>
	import('./pages/transaction/TransactionsListPage')
);

// auth
const Login = React.lazy(() => import('./pages/auth/LoginPage'));
const Logout = React.lazy(() => import('./pages/auth/LogoutPage'));
const ForgetPassword = React.lazy(() => import('./pages/account/ForgetPasswordPage'));
const ConfirmAccount = React.lazy(() => import('./pages/account/ConfirmPage'));

const routes = [
	// auth and account
	{ path: Routes.login, name: 'Login', component: Login, route: Route },
	{ path: Routes.logout, name: 'Logout', component: Logout, route: Route },
	{ path: Routes.forgetPassword, name: 'Forget Password', component: ForgetPassword, route: Route },
	{ path: Routes.confirm, name: 'Confirm', component: ConfirmAccount, route: Route },

	// other pages
	{
		path: Routes.companyAdd,
		name: 'DashboardCompanyCreator',
		component: DashboardCompanyCreator,
		route: PrivateRoute,
		roles: ['ADMIN']
	},
	{
		path: Routes.companyList,
		name: 'Dashboard',
		component: DashboardCompaniesList,
		route: PrivateRoute,
		roles: ['ADMIN']
	},
	{
		path: Routes.transactionsList,
		name: 'Dashboard',
		component: DashboardTransactionsList,
		route: PrivateRoute
	},
	{
		path: Routes.comapanyDetails(':id'),
		name: 'Dashboard',
		component: DashboardCompanyDetails,
		route: PrivateRoute
	},
	{
		path: Routes.gatewayAcquirers,
		name: 'Dashboard',
		component: DashboardGatewayAcquirers,
		route: PrivateRoute
	},
	{
		path: Routes.gatewayBrands,
		name: 'Dashboard',
		component: DashboardGatewayBrands,
		route: PrivateRoute
	},

	{
		path: '/',
		exact: true,
		component: () => <Redirect to={Routes.companyList} />,
		route: PrivateRoute
	}
];

export { routes, PrivateRoute };
