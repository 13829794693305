import { Cookies } from "react-cookie";

const isUserAuthenticated = () => {
    const cookies = new Cookies(); 
    const token = cookies.get("token");
    if (!token) {
        return false;
    } else {
        return true;
    }
}

const getLoggedInUser = () => {
    const cookies = new Cookies(); 
    const token = cookies.get("token");
    return token ? (typeof(token) == 'object'? token: JSON.parse(token)) : null;
}

const getTokenFromLoggedUser = () => {
    const cookies = new Cookies(); 
    return cookies.get('token').token;
}

const setUserTokenOnCookie = (token) => {
    const cookies = new Cookies();
    cookies.set('token', token);
}

const getUserTokenFromCookie = () => {
    const cookies = new Cookies(); 
    return cookies.get('token');
}

const removeUserTokenFromCookie = () => {
    const cookies = new Cookies(); 
    cookies.remove('token');
    return true;
}

const setAdminEditingCompanyId = (companyId) => {
    const cookies = new Cookies();
    cookies.set('companyId', companyId, { path: '/' });
}

const getAdminEditingCompanyId = () => {
    const cookies = new Cookies(); 
    return cookies.get('companyId');
}

const setCompany = (company) => {
    const cookies = new Cookies();
    cookies.set('company', company, { path: '/' });
}

const getCompany = () => {
    const cookies = new Cookies(); 
    return cookies.get('company');
}

const removeCompany = () => {
    const cookies = new Cookies(); 
    cookies.remove('company');
    return true;
}

export {
    isUserAuthenticated, // ok
    getLoggedInUser,
    getTokenFromLoggedUser,
    getUserTokenFromCookie, // ok
    removeUserTokenFromCookie, // ok
    setUserTokenOnCookie, // ok
    setAdminEditingCompanyId, // ok
    getAdminEditingCompanyId, // ok
    setCompany,
    getCompany,
    removeCompany,
};