import {
    CREATE_COMPANY_ACQUIRER,
    CREATE_COMPANY_ACQUIRER_SUCCESS,
    CREATE_COMPANY_ACQUIRER_FAILED,
    DELETE_COMPANY_ACQUIRER,
    DELETE_COMPANY_ACQUIRER_SUCCESS,
    DELETE_COMPANY_ACQUIRER_FAILED,
} from '../../constants/actionTypes';

export const createCompanyAcquirer = (data) => ({
    type: CREATE_COMPANY_ACQUIRER,
    payload: data,
});

export const createCompanyAcquirerSuccess = (companyAcquirer) => ({
    type: CREATE_COMPANY_ACQUIRER_SUCCESS,
    payload: companyAcquirer,
});

export const createCompanyAcquirerFailed = (error) => ({
    type: CREATE_COMPANY_ACQUIRER_FAILED,
    payload: error,
});

export const deleteCompanyAcquirer = (data) => ({
    type: DELETE_COMPANY_ACQUIRER,
    payload: data,
});

export const deleteCompanyAcquirerSuccess = () => ({
    type: DELETE_COMPANY_ACQUIRER_SUCCESS,
    payload: null,
});

export const deleteCompanyAcquirerFailed = (error) => ({
    type: DELETE_COMPANY_ACQUIRER_FAILED,
    payload: error,
});