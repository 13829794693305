import {
    CREATE_COMPANY_ACQUIRER,
    CREATE_COMPANY_ACQUIRER_SUCCESS,
    CREATE_COMPANY_ACQUIRER_FAILED,
    DELETE_COMPANY_ACQUIRER,
    DELETE_COMPANY_ACQUIRER_SUCCESS,
    DELETE_COMPANY_ACQUIRER_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    companyAcquirer: null,
    loading: false,
    error: false,
};

const CompanyAcquirer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_COMPANY_ACQUIRER:
            return { ...state, loading: true, error: null };
        case CREATE_COMPANY_ACQUIRER_SUCCESS:
            return { ...state, companyAcquirer: action.payload, loading: false, error: null };
        case CREATE_COMPANY_ACQUIRER_FAILED:
            return { ...state, loading: false, error: true };
        case DELETE_COMPANY_ACQUIRER:
            return { ...state, loading: true, error: null };
        case DELETE_COMPANY_ACQUIRER_SUCCESS:
            return { ...state, loading: false, error: null };
        case DELETE_COMPANY_ACQUIRER_FAILED:
            return { ...state, loading: false, error: true };
        default:
            return state;
    }
}

export default CompanyAcquirer;