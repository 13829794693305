import {
    FETCH_COMPANY,
    EDIT_COMPANY,
} from "../../constants/actionTypes";
import {
    fetchCompanySuccess,
    fetchCompanyFailed,
    editCompanySuccess,
    editCompanyFailed,
} from './actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    getUserTokenFromCookie,
} from '../../helpers/authUtils';
import { request } from '../request';

function* fetchCompany({ payload }) {
    try {
        const token = getUserTokenFromCookie();
        const response = yield call(request, `/companies/${payload}`, 'get', token);
        yield put(fetchCompanySuccess(response.data.data));
    } catch (error) {
        yield put(fetchCompanyFailed());
    }
}

function* editCompany({ payload }) {
    try {
        const company = payload.company;
        const companyId = payload.companyId;
        const token = getUserTokenFromCookie();
        const body = {
            ...company
        };
        const response = yield call(request, `/companies/${companyId}`, 'put', token, body);
        yield put(editCompanySuccess(response.data.data));
    } catch (error) {
        yield put(editCompanyFailed(error));
    }
}

export function* watchCompanyFetching() {
    yield takeEvery(FETCH_COMPANY, fetchCompany);
}

export function* watchCompanyEditing() {
    yield takeEvery(EDIT_COMPANY, editCompany);
}

function* authSaga() {
    yield all([
        fork(watchCompanyFetching),
        fork(watchCompanyEditing),
    ]);
}

export default authSaga;