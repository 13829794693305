import axios from 'axios';
import { getUserTokenFromCookie } from '../helpers/authUtils';
const BASE_URL = process.env.REACT_APP_API_ROOT_URL;

const authHeader = (token) => ({
    headers: {
        'Content-Type': 'application/json',
        'Authorization': token.type + ' ' + token.token,
    }
})

const request = (url, method, token, body = null) => {
    const header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token.type + ' ' + token.token,
        }
    };
    if (method === 'get') {
        return axios[method](`${BASE_URL}${url}`, header);
    } else if (method === 'post' || method === 'put') {
        return axios[method](`${BASE_URL}${url}`, body, header);
    } else if (method === 'delete') {
        return axios[method](`${BASE_URL}${url}`, {
            ...header,
            data: body,
        });
    }
}

const authRequest = (method, url, body = {}) => {
    const token = getUserTokenFromCookie();
    const header = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': token.type + ' ' + token.token,
        }
    };
    if (method === 'get') {
        return axios[method](`${BASE_URL}${url}`, header);
    } else if (method === 'post' || method === 'put') {
        return axios[method](`${BASE_URL}${url}`, body, header);
    } else if (method === 'delete') {
        return axios[method](`${BASE_URL}${url}`, {
            ...header,
            data: body,
        });
    }
}

const noAuthRequest = (method, url, body = {}) => {
    const header = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    if (method === 'get') {
        return axios[method](`${BASE_URL}${url}`, header);
    } else if (method === 'post' || method === 'put') {
        return axios[method](`${BASE_URL}${url}`, body, header);
    } else if (method === 'delete') {
        return axios[method](`${BASE_URL}${url}`, {
            ...header,
            data: body,
        });
    }
}

export {
    BASE_URL,
    authHeader,
    request,
    
    authRequest,
    noAuthRequest,
};