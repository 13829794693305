/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';
export const FETCH_AUTH_USER = 'FETCH_AUTH_USER';
export const FETCH_AUTH_USER_SUCCESS = 'FETCH_AUTH_USER_SUCCESS';
export const FETCH_AUTH_USER_FAILED = 'FETCH_AUTH_USER_FAILED';

/* COMPANIES */
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAILED = 'CREATE_COMPANY_FAILED';
export const LIST_COMPANIES = 'LIST_COMPANIES';
export const LIST_COMPANIES_SUCCESS = 'LIST_COMPANIES_SUCCESS';
export const LIST_COMPANIES_FAILED = 'LIST_COMPANIES_FAILED';
export const CLEAN_COMPANIES = 'CLEAN_COMPANIES';

/* COMPANY */
export const FETCH_COMPANY = 'FETCH_COMPANY';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAILED = 'FETCH_COMPANY_FAILED';
export const EDIT_COMPANY = 'EDIT_COMPANY';
export const EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS';
export const EDIT_COMPANY_FAILED = 'EDIT_COMPANY_FAILED';

/* ACQUIRERS */
export const LIST_ACQUIRERS = 'LIST_ACQUIRERS';
export const LIST_ACQUIRERS_SUCCESS = 'LIST_ACQUIRERS_SUCCESS';
export const LIST_ACQUIRERS_FAILED = 'LIST_ACQUIRERS_FAILED';

/* COMPANY_ACQUIRER */
export const CREATE_COMPANY_ACQUIRER = 'CREATE_COMPANY_ACQUIRER';
export const CREATE_COMPANY_ACQUIRER_SUCCESS = 'LIST_ACQUIRERS_FAILED';
export const CREATE_COMPANY_ACQUIRER_FAILED = 'CREATE_COMPANY_ACQUIRER_FAILED';
export const DELETE_COMPANY_ACQUIRER = 'DELETE_COMPANY_ACQUIRER';
export const DELETE_COMPANY_ACQUIRER_SUCCESS = 'DELETE_COMPANY_ACQUIRER_SUCCESS';
export const DELETE_COMPANY_ACQUIRER_FAILED = 'DELETE_COMPANY_ACQUIRER_FAILED';

/* BRANDS */
export const LIST_BRANDS = 'LIST_BRANDS';
export const LIST_BRANDS_SUCCESS = 'LIST_BRANDS_SUCCESS';
export const LIST_BRANDS_FAILED = 'LIST_BRANDS_FAILED';

/* COMPANY_ACQUIRER */
export const CREATE_COMPANY_ACQUIRER_BRAND = 'CREATE_COMPANY_ACQUIRER_BRAND';
export const CREATE_COMPANY_ACQUIRER_BRAND_SUCCESS = 'CREATE_COMPANY_ACQUIRER_BRAND_SUCCESS';
export const CREATE_COMPANY_ACQUIRER_BRAND_FAILED = 'CREATE_COMPANY_ACQUIRER_BRAND_FAILED';
export const DELETE_COMPANY_ACQUIRER_BRAND = 'DELETE_COMPANY_ACQUIRER_BRAND';
export const DELETE_COMPANY_ACQUIRER_BRAND_SUCCESS = 'DELETE_COMPANY_ACQUIRER_BRAND_SUCCESS';
export const DELETE_COMPANY_ACQUIRER_BRAND_FAILED = 'DELETE_COMPANY_ACQUIRER_BRAND_FAILED';

/* BILL */
export const CREATE_BILL = 'CREATE_BILL';
export const CREATE_BILL_SUCCESS = 'CREATE_BILL_SUCCESS';
export const CREATE_BILL_FAILED = 'CREATE_BILL_FAILED';
export const CLEAN_BILL = 'CLEAN_BILL';

/* TRANSACTION */
export const LIST_TRANSACTIONS = 'LIST_TRANSACTIONS';
export const LIST_TRANSACTIONS_SUCCESS = 'LIST_TRANSACTIONS_SUCCESS';
export const LIST_TRANSACTIONS_FAILED = 'LIST_TRANSACTIONS_FAILED';

/* USERS */
export const LIST_USERS = 'LIST_USERS';
export const LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS';
export const LIST_USERS_FAILED = 'LIST_USERS_FAILED';

/* USER */
export const MANAGE_USER_ADMIN = 'MANAGE_USER_ADMIN';
export const MANAGE_USER_ADMIN_SUCCESS = 'MANAGE_USER_ADMIN_SUCCESS';
export const MANAGE_USER_ADMIN_FAILED = 'MANAGE_USER_ADMIN_FAILED';
export const MANAGE_USER_COMPANY = 'MANAGE_USER_COMPANY';
export const MANAGE_USER_COMPANY_SUCCESS = 'MANAGE_USER_COMPANY_SUCCESS';
export const MANAGE_USER_COMPANY_FAILED = 'MANAGE_USER_COMPANY_FAILED';