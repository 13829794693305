// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    FETCH_AUTH_USER_FAILED,
    FETCH_AUTH_USER_SUCCESS,
} from '../../constants/actionTypes';

import { getUserTokenFromCookie } from '../../helpers/authUtils';

const INIT_STATE = {
    user: null,
    token: getUserTokenFromCookie(),
    loading: false,
};

const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };
        case LOGIN_USER_FAILED:
            return { ...state, error: action.payload, loading: false };
        case REGISTER_USER:
            return { ...state, loading: true };
        case REGISTER_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };
        case REGISTER_USER_FAILED:
            return { ...state, error: action.payload, loading: false };
        case LOGOUT_USER:
            return { ...state, token: null, user: null, loading: false };
        case FORGET_PASSWORD:
            return { ...state, loading: true };
        case FORGET_PASSWORD_SUCCESS:
            return { ...state, passwordResetStatus: action.payload, loading: false, error: null };
        case FORGET_PASSWORD_FAILED:
            return { ...state, error: action.payload, loading: false };
        case FETCH_AUTH_USER_SUCCESS:
            return { ...state, user: action.payload, loading: false, error: null };
        case FETCH_AUTH_USER_FAILED:
            return { ...state, error: action.payload, loading: false };
        default: return { ...state };
    }
}

export default Auth;