// @flow
import {
    LIST_TRANSACTIONS,
    LIST_TRANSACTIONS_SUCCESS,
    LIST_TRANSACTIONS_FAILED,
} from '../../constants/actionTypes';

export const listTransactions = () => {
    return {
        type: LIST_TRANSACTIONS,
        payload: null,
    }
}

export const listTransactionsSuccess = (transactions) => {
    return {
        type: LIST_TRANSACTIONS_SUCCESS,
        payload: transactions,
    }
}

export const listTransactionsFailed = (error) => {
    return {
        type: LIST_TRANSACTIONS_FAILED,
        payload: error,
    }
}