import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import companiesSaga from './companies/saga';
import companySaga from './company/saga';
import acquirersSaga from './acquirers/saga';
import companyAcquirerSaga from './company_acquirers/saga';
import brandsSaga from './brands/saga';
import companyAcquirerBrandSaga from './company_acquirer_brand/saga';
import billSaga from './bill/saga';
import transactionsSaga from './transactions/saga';
import usersSaga from './users/saga';
import userSaga from './user/saga';

export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        companiesSaga(),
        companySaga(),
        acquirersSaga(),
        companyAcquirerSaga(),
        brandsSaga(),
        companyAcquirerBrandSaga(),
        billSaga(),
        transactionsSaga(),
        usersSaga(),
        userSaga(),
    ]);
}
