// @flow
import {
    LIST_ACQUIRERS,
    LIST_ACQUIRERS_SUCCESS,
    LIST_ACQUIRERS_FAILED,
} from '../../constants/actionTypes';

export const fetchAcquirers = () => ({
    type: LIST_ACQUIRERS,
    payload: null,
});

export const fetchAcquirersSuccess = (acquirers) => ({
    type: LIST_ACQUIRERS_SUCCESS,
    payload: acquirers,
});

export const fetchAcquirersFailed = (error) => ({
    type: LIST_ACQUIRERS_FAILED,
    payload: error,
});