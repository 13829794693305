// @flow
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    FETCH_AUTH_USER,
    FETCH_AUTH_USER_SUCCESS,
    FETCH_AUTH_USER_FAILED,
} from '../../constants/actionTypes';

export const fetchAuthUser = () => ({
    type: FETCH_AUTH_USER,
    payload: null,
});

export const fetchAuthUserSuccess = (user) => ({
    type: FETCH_AUTH_USER_SUCCESS,
    payload: user,
});

export const fetchAuthUserFailed = (error) => ({
    type: FETCH_AUTH_USER_FAILED,
    payload: error,
});

export const loginUser = (username, password, history) => ({
    type: LOGIN_USER,
    payload: { username, password, history }
});

export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user
});

export const loginUserFailed = (error) => ({
    type: LOGIN_USER_FAILED,
    payload: error
});

export const registerUser = (fullname, email, password) => ({
    type: REGISTER_USER,
    payload: { fullname, email, password }
});

export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user
});

export const registerUserFailed = (error) => ({
    type: REGISTER_USER_FAILED,
    payload: error
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history }
});

export const forgetPassword = (username) => ({
    type: FORGET_PASSWORD,
    payload: { username }
});

export const forgetPasswordSuccess = (passwordResetStatus) => ({
    type: FORGET_PASSWORD_SUCCESS,
    payload: passwordResetStatus
});

export const forgetPasswordFailed = (error) => ({
    type: FORGET_PASSWORD_FAILED,
    payload: error
});