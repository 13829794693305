import React from 'react';
import {connect} from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { isUserAuthenticated, removeUserTokenFromCookie } from './helpers/authUtils';
import Routes from './constants/routes';
import { selectIsAdmin } from './redux/reducers';

const PrivateRoute = ({ component: Component, roles, admin, hasCompany, ...rest }) => (
    <Route {...rest} render={props => {
        const isAuthTokenValid = isUserAuthenticated();
        if (!isAuthTokenValid) {
            removeUserTokenFromCookie();
            return (
                <Redirect
                    to={{ pathname: Routes.login, state: { from: props.location } }}
                />
            );
        }
        if (roles && roles.find(role => role === "ADMIN") && admin === false) {
            return (
                <h1 className="text-muted text-center ml-1 mr-1 mt-5 mb-5">
                    Você não é administrador para acessar este local
                </h1>
            )
        }
        return <Component {...props} />
    }} />
)

function mapStateToProps(state) {
    return {
        admin: selectIsAdmin(state),
    }
}

export default connect(mapStateToProps)(PrivateRoute);
