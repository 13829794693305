// @flow
import {
    CREATE_COMPANY_ACQUIRER_BRAND,
    CREATE_COMPANY_ACQUIRER_BRAND_SUCCESS,
    CREATE_COMPANY_ACQUIRER_BRAND_FAILED,
    DELETE_COMPANY_ACQUIRER_BRAND_SUCCESS,
    DELETE_COMPANY_ACQUIRER_BRAND_FAILED,
    DELETE_COMPANY_ACQUIRER_BRAND,
} from '../../constants/actionTypes';

export const createCompanyAcquirerBrand = (values) => {
    return {
        type: CREATE_COMPANY_ACQUIRER_BRAND,
        payload: values,
    }
}

export const createCompanyAcquirerBrandSuccess = (values) => {
    return {
        type: CREATE_COMPANY_ACQUIRER_BRAND_SUCCESS,
        payload: values,
    }
}

export const createCompanyAcquirerBrandFailed = (error) => {
    return {
        type: CREATE_COMPANY_ACQUIRER_BRAND_FAILED,
        payload: error,
    }
}

export const deleteCompanyAcquirerBrand = (data) => {
    return {
        type: DELETE_COMPANY_ACQUIRER_BRAND,
        payload: data,
    }
}

export const deleteCompanyAcquirerBrandSuccess = () => {
    return {
        type: DELETE_COMPANY_ACQUIRER_BRAND_SUCCESS,
        payload: null,
    }
}

export const deleteCompanyAcquirerBrandFailed = () => {
    return {
        type: DELETE_COMPANY_ACQUIRER_BRAND_FAILED,
        payload: null,
    }
}
