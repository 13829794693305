// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_ACQUIRERS } from '../../constants/actionTypes';
import {
    fetchAcquirersSuccess,
    fetchAcquirersFailed,
} from './actions';
import { getUserTokenFromCookie, getCompany } from '../../helpers/authUtils';
import { request } from '../request';

function* listAcquirers() {
    try {
        const token = getUserTokenFromCookie();
        const company = getCompany();
        const response = yield call(request, `/companies/${company.id}/all-acquirers`, 'get', token);
        yield put(fetchAcquirersSuccess(response.data.data));
    } catch (error) {
        yield put(fetchAcquirersFailed(error));
    }
}

export function* watchAcquirersListing() {
    yield takeEvery(LIST_ACQUIRERS, listAcquirers);
}

function* authSaga() {
    yield all([
        fork(watchAcquirersListing),
    ]);
}

export default authSaga;
