// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    listBrandsSuccess,
    listBrandsFailed,
} from './actions';
import {
    LIST_BRANDS,
} from '../../constants/actionTypes';
import { getUserTokenFromCookie, getCompany } from '../../helpers/authUtils';
import { request } from '../request';

function* list() {
    try {
        const token = getUserTokenFromCookie();
        const company = getCompany();
        const brandsResponse = yield call(request, `/companies/${company.id}/brands-acquirers/`, 'get', token);
        const selectedBrandsResponse = yield call(request, `/companies/${company.id}/active-brands-acquirers`, 'get', token);
        yield put(listBrandsSuccess({
            brands: brandsResponse.data.data,
            selectedBrands: selectedBrandsResponse.data.data,
        }));
    } catch (error) {
        yield put(listBrandsFailed());
    }
}

export function* watchBrandsListing() {
    yield takeEvery(LIST_BRANDS, list);
}

function* authSaga() {
    yield all([
        fork(watchBrandsListing),
    ]);
}

export default authSaga;