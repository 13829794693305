// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    manageUserAdminSuccess,
    manageUserAdminFailed,
    manageUserCompanySuccess,
    manageUserCompanyFailed,
} from './actions';
import {
    listUsers,
} from '../users/actions';
import {
    MANAGE_USER_ADMIN,
    MANAGE_USER_COMPANY,
} from '../../constants/actionTypes';
import { authRequest } from '../request';

function* manageUserAdmin({ payload }) {
    try {
        const body = {
            setAdmin: payload.setAdmin
        };
        const response = yield call(authRequest, 'put', `/users/set-admin/${payload.userId}`, body);
        yield put(manageUserAdminSuccess(response));
        yield put(listUsers());
    } catch (error) {
        yield put(manageUserAdminFailed(error.response));
    }
}

function* manageUserCompany({ payload }) {
    try {
        const body = {
            setCompany: payload.setCompany,
            company_document: payload.companyDocument,
        };
        const response = yield call(authRequest, 'put', `/users/set-company/${payload.userId}`, body);
        yield put(manageUserCompanySuccess(response));
        yield put(listUsers());
    } catch (error) {
        yield put(manageUserCompanyFailed(error.response));
    }
}

export function* watchManageUserAdmin() {
    yield takeEvery(MANAGE_USER_ADMIN, manageUserAdmin);
}

export function* watchManageUserCompany() {
    yield takeEvery(MANAGE_USER_COMPANY, manageUserCompany);
}

function* authSaga() {
    yield all([
        fork(watchManageUserAdmin),
        fork(watchManageUserCompany),
    ]);
}

export default authSaga;