// @flow
import {
    LIST_ACQUIRERS,
    LIST_ACQUIRERS_SUCCESS,
    LIST_ACQUIRERS_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    list: [],
    loading: false,
    error: false,
};

const Acquirers = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_ACQUIRERS:
            return { ...state, loading: true, error: null };
        case LIST_ACQUIRERS_SUCCESS:
            return { ...state, list: action.payload, loading: false, error: null };    
        case LIST_ACQUIRERS_FAILED:
            return { ...state, loading: false, error: true };
        default:
            return state;
    }
}

export default Acquirers;