// @flow
import {
	LIST_TRANSACTIONS,
	LIST_TRANSACTIONS_SUCCESS,
	LIST_TRANSACTIONS_FAILED
} from '../../constants/actionTypes';

const INIT_STATE = {
	list: [],
	loading: false,
	error: false
};

const Reducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case LIST_TRANSACTIONS:
			return { ...state, loading: true };
		case LIST_TRANSACTIONS_SUCCESS:
			return { ...state, list: action.payload, loading: false };
		case LIST_TRANSACTIONS_FAILED:
			return { ...state, list: [], loading: false, error: action.payload };
		default:
			return state;
	}
};

export default Reducer;
