// @flow
import {
    MANAGE_USER_ADMIN,
    MANAGE_USER_ADMIN_SUCCESS,
    MANAGE_USER_ADMIN_FAILED,
    MANAGE_USER_COMPANY,
    MANAGE_USER_COMPANY_SUCCESS,
    MANAGE_USER_COMPANY_FAILED,
} from '../../constants/actionTypes';

export const manageUserAdmin = (values) => {
    return {
        type: MANAGE_USER_ADMIN,
        payload: values,
    }
};

export const manageUserAdminSuccess = (result) => {
    return {
        type: MANAGE_USER_ADMIN_SUCCESS,
        payload: result,
    }
};

export const manageUserAdminFailed = (error) => {
    return {
        type: MANAGE_USER_ADMIN_FAILED,
        payload: error,
    }
};

export const manageUserCompany = (values) => {
    return {
        type: MANAGE_USER_COMPANY,
        payload: values,
    }
};

export const manageUserCompanySuccess = (result) => {
    return {
        type: MANAGE_USER_COMPANY_SUCCESS,
        payload: result,
    }
};

export const manageUserCompanyFailed = (error) => {
    return {
        type: MANAGE_USER_COMPANY_FAILED,
        payload: error,
    }
};