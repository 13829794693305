// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { listTransactionsSuccess, listTransactionsFailed } from './actions';
import { LIST_TRANSACTIONS } from '../../constants/actionTypes';
import { getUserTokenFromCookie, getCompany } from '../../helpers/authUtils';
import { request } from '../request';

function* list() {
	const token = getUserTokenFromCookie();
	const company = getCompany();
	try {
		//FIXME: dynamic this
		const body = {
			start_date: '01/01/2000',
			end_date: '01/01/2100'
		};
		const response = yield call(
			request,
			`/companies/${company.id}/transactions`,
			'get',
			token,
			body
		);
		yield put(listTransactionsSuccess(response.data.data));
	} catch (error) {
		yield put(listTransactionsFailed(error));
	}
}

export function* watchListing() {
	yield takeEvery(LIST_TRANSACTIONS, list);
}

function* authSaga() {
	yield all([fork(watchListing)]);
}

export default authSaga;
