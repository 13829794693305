// @flow
import {
    CREATE_BILL,
    CREATE_BILL_SUCCESS,
    CREATE_BILL_FAILED,
    CLEAN_BILL,
} from '../../constants/actionTypes';

const INIT_STATE = {
    item: null,
    created: false,
    loading: false,
    error: false,
};

const Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_BILL:
            return { ...state, loading: true, error: null };
        case CREATE_BILL_SUCCESS:
            return { ...state, item: action.payload, created: true, loading: false, error: null };    
        case CREATE_BILL_FAILED:
            return { ...state, loading: true, error: true };
        case CLEAN_BILL:
            return { ...INIT_STATE };
        default:
            return state;
    }
}

export default Reducer;