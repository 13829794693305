// @flow
import {
    CREATE_COMPANY_ACQUIRER_BRAND,
    CREATE_COMPANY_ACQUIRER_BRAND_SUCCESS,
    CREATE_COMPANY_ACQUIRER_BRAND_FAILED,
    DELETE_COMPANY_ACQUIRER_BRAND,
    DELETE_COMPANY_ACQUIRER_BRAND_SUCCESS,
    DELETE_COMPANY_ACQUIRER_BRAND_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    list: [],
    loading: false,
    error: false,
};

const Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_COMPANY_ACQUIRER_BRAND:
            return { ...state, loading: true, error: null }
        case CREATE_COMPANY_ACQUIRER_BRAND_SUCCESS:
            return { ...state, loading: false, error: null }
        case CREATE_COMPANY_ACQUIRER_BRAND_FAILED:
            return { ...state, loading: false, error: action.payload }
        case DELETE_COMPANY_ACQUIRER_BRAND:
            return { ...state, loading: true, error: null }
        case DELETE_COMPANY_ACQUIRER_BRAND_SUCCESS:
            return { ...state, loading: false, error: null }
        case DELETE_COMPANY_ACQUIRER_BRAND_FAILED:
            return { ...state, loading: false, error: action.payload }
        default:
            return state;
    }
}

export default Reducer;