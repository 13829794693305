import {
    CREATE_COMPANY_ACQUIRER,
    DELETE_COMPANY_ACQUIRER,
} from "../../constants/actionTypes";
import {
    createCompanyAcquirerFailed,
    deleteCompanyAcquirerFailed,
} from './actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getUserTokenFromCookie, getCompany } from "../../helpers/authUtils";
import { fetchAcquirers } from "../acquirers/actions";
import { request } from '../request';

function* createCompanyAcquirer ({ payload }) {
    const token = getUserTokenFromCookie();
    const company = getCompany();
    try {
        const body = {
            "acquirerSlug": payload.acquirerSlug,
            "publicKey": payload.publicKey,
            "privateKey": payload.privateKey
        };
        const response = yield call(request, `/companies/${company.id}/acquirers`, 'post', token, body);
        yield put(fetchAcquirers(response.data));
    } catch (error) {
        yield put(createCompanyAcquirerFailed());
    }
}

function* deleteCompanyAcquirer ({ payload }) {
    try {
        const token = getUserTokenFromCookie();
        const company = getCompany();
        const body = {
            "acquirerSlug": payload.acquirerSlug,
        };
        const response = yield call(request, `/companies/${company.id}/acquirers`, 'delete', token, body);
        yield put(fetchAcquirers(response.data));
    } catch (error) {
        yield put(deleteCompanyAcquirerFailed(error));
    }
}

export function* watchCompanyAcquirerDeletion() {
    yield takeEvery(DELETE_COMPANY_ACQUIRER, deleteCompanyAcquirer);
}

export function* watchCompanyAcquirerCreation() {
    yield takeEvery(CREATE_COMPANY_ACQUIRER, createCompanyAcquirer);
}

function* authSaga() {
    yield all([
        fork(watchCompanyAcquirerCreation),
        fork(watchCompanyAcquirerDeletion),
    ]);
}

export default authSaga;