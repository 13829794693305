// @flow
import {
    CREATE_BILL,
    CREATE_BILL_SUCCESS,
    CREATE_BILL_FAILED,
    CLEAN_BILL,
} from '../../constants/actionTypes';

export const createBill = (values) => {
    return {
        type: CREATE_BILL,
        payload: values,
    }
};

export const createBillSuccess = (values) => {
    return {
        type: CREATE_BILL_SUCCESS,
        payload: values,
    }
};

export const createBillFailed = (values) => {
    return {
        type: CREATE_BILL_FAILED,
        payload: values,
    }
};

export const cleanBill = () => {
    return {
        type: CLEAN_BILL,
        payload: null,
    }
}