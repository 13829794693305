// @flow
import {
    LIST_BRANDS,
    LIST_BRANDS_SUCCESS,
    LIST_BRANDS_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    list: [],
    listSelecteds: [],
    loading: false,
    error: false,
};

const Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LIST_BRANDS:
            return { ...state, loading: true, error: null };
        case LIST_BRANDS_SUCCESS:
            return { ...state, list: action.payload.brands, listSelecteds: action.payload.selectedBrands, loading: false, error: null };    
        case LIST_BRANDS_FAILED:
            return { ...state, loading: false, error: true };
        default:
            return state;
    }
}

export default Reducer;