// @flow
import {
    CREATE_COMPANY,
    CREATE_COMPANY_SUCCESS,
    CREATE_COMPANY_FAILED,
    LIST_COMPANIES,
    LIST_COMPANIES_SUCCESS,
    LIST_COMPANIES_FAILED,
    CLEAN_COMPANIES,
} from '../../constants/actionTypes';

export const createCompany = (values) => {
    return {
        type: CREATE_COMPANY,
        payload: values,
    }
};


export const createCompanySuccess = (values) => {
    return {
        type: CREATE_COMPANY_SUCCESS,
        payload: values,
    }
};


export const createCompanyFailed = (values) => {
    return {
        type: CREATE_COMPANY_FAILED,
        payload: values,
    }
};


export const listCompanies = () => {
    return {
        type: LIST_COMPANIES,
        payload: null,
    }
}

export const listCompaniesSuccess = (companies) => {
    return {
        type: LIST_COMPANIES_SUCCESS,
        payload: companies,
    }
}


export const listCompaniesFailed = (error) => {
    return {
        type: LIST_COMPANIES_FAILED,
        payload: error,
    }
};

export const cleanCompanies = () => {
    return {
        type: CLEAN_COMPANIES,
        payload: null,
    }
}
