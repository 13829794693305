// @flow
import {
    FETCH_COMPANY,
    FETCH_COMPANY_SUCCESS,
    FETCH_COMPANY_FAILED,
    EDIT_COMPANY,
    EDIT_COMPANY_SUCCESS,
    EDIT_COMPANY_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    company: null,
    loading: false,
    error: false,
};

const Company = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_COMPANY:
            return { ...state, loading: true, error: null };
        case FETCH_COMPANY_SUCCESS:
            return { ...state, company: action.payload, loading: false, error: null };
        case FETCH_COMPANY_FAILED:
            return { ...state, loading: false, error: true };
        case EDIT_COMPANY:
            return { ...state, loading: true, error: null };
        case EDIT_COMPANY_SUCCESS:
            return { ...state, company: action.payload, loading: false, error: null, success: true };
        case EDIT_COMPANY_FAILED:
            return { ...state, loading: false, error: true };    
        default:
            return state;
    }
}

export default Company;