// @flow
import {
    LIST_BRANDS,
    LIST_BRANDS_SUCCESS,
    LIST_BRANDS_FAILED,
} from '../../constants/actionTypes';

export const listBrands = () => {
    return {
        type: LIST_BRANDS,
        payload: null,
    }
}

export const listBrandsSuccess = (payload) => {
    return {
        type: LIST_BRANDS_SUCCESS,
        payload: payload,
    }
}

export const listBrandsFailed = () => {
    return {
        type: LIST_BRANDS_FAILED,
        payload: null,
    }
}
