// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    listCompaniesSuccess,
    listCompaniesFailed,
    createCompanySuccess,
    createCompanyFailed
} from './actions';
import { CREATE_COMPANY, LIST_COMPANIES } from '../../constants/actionTypes';
import { getUserTokenFromCookie } from '../../helpers/authUtils';
import { request } from '../request';

function* create({ payload }) {
    try {
        const token = getUserTokenFromCookie();
        const body = payload;
        const response = yield call(request, `/companies`, 'post', token, body);
        yield put(createCompanySuccess(response.data.data));
    } catch (error) {
        yield put(createCompanyFailed(error.response));
    }
}

function* list() {
    try {
        const token = getUserTokenFromCookie();
        const response = yield call(request, `/companies`, 'get', token);
        yield put(listCompaniesSuccess(response.data.data));
    } catch (error) {
        yield put(listCompaniesFailed(error));
    }
}

export function* watchCompanyCreation() {
    yield takeEvery(CREATE_COMPANY, create);
}

export function* watchCompanyListing() {
    yield takeEvery(LIST_COMPANIES, list);
}

function* authSaga() {
    yield all([
        fork(watchCompanyCreation),
        fork(watchCompanyListing),
    ]);
}

export default authSaga;