import {
    MANAGE_USER_ADMIN,
    MANAGE_USER_ADMIN_SUCCESS,
    MANAGE_USER_ADMIN_FAILED,
    MANAGE_USER_COMPANY,
    MANAGE_USER_COMPANY_SUCCESS,
    MANAGE_USER_COMPANY_FAILED,
} from '../../constants/actionTypes';

const INIT_STATE = {
    item: null,
    success: false,
    loading: false,
    error: false,
}

const Reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case MANAGE_USER_ADMIN:
            return { ...state, loading: true, error: null };
        case MANAGE_USER_ADMIN_SUCCESS:
            return { ...state, item: action.payload, loading: false, success: true, error: null };
        case MANAGE_USER_ADMIN_FAILED:
            return { ...state, loading: false, error: action.payload };
        case MANAGE_USER_COMPANY:
            return { ...state, loading: true, error: null };
        case MANAGE_USER_COMPANY_SUCCESS:
            return { ...state, item: action.payload, loading: false, success: true, error: null };
        case MANAGE_USER_COMPANY_FAILED:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
}

export default Reducer;
