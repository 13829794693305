import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Companies from './companies/reducer';
import Company from './company/reducer';
import Acquirers from './acquirers/reducer';
import CompanyAcquirer from './company_acquirers/reducer';
import Brands from './brands/reducer';
import CompanyAcquirerBrand from './company_acquirer_brand/reducer';
import Bill from './bill/reducer';
import Transactions from './transactions/reducer';
import Users from './users/reducer';
import User from './user/reducer';

export default combineReducers({
    Auth,
    Companies,
    Company,
    Acquirers,
    CompanyAcquirer,
    Brands,
    CompanyAcquirerBrand,
    Bill,
    Transactions,
    Users,
    User,
});

const selectIsAdmin = (state) => {
    if (state.Auth.user) {
        const roleAdmin = state.Auth.user.roles.find((role) => role.slug === "ADMIN");
        if (roleAdmin) {
            return true;
        } else {
            return false;
        }
    }
    return null;
}

const selectHasCompany = (state) => {
    if (state.Auth.user) {
        if (state.Auth.user.company_id) {
            return true;
        } else {
            return false;
        }
    }
    return null;
}

export {
    selectIsAdmin,
    selectHasCompany,
};