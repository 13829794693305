// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    createBillSuccess,
    createBillFailed,
} from './actions';
import {
    CREATE_BILL,
} from '../../constants/actionTypes';
import { getUserTokenFromCookie } from '../../helpers/authUtils';
import { request } from '../request';

function* create({ payload }) {
    try {
        const token = getUserTokenFromCookie();
        const body = {
            type: "BILL",
            document: payload.document,
            data: {
                description: payload.description,
                value: payload.value,
            }
        };
        const response = yield call(request, `/dashboard/qrcodes`, 'post', token, body);
        yield put(createBillSuccess(response.data));
    } catch (error) {
        yield put(createBillFailed(error.response));
    }
}

export function* watchCreation() {
    yield takeEvery(CREATE_BILL, create);
}

function* authSaga() {
    yield all([
        fork(watchCreation),
    ]);
}

export default authSaga;